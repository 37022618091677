import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  title: {
    id: 'components.Modal.HttpErrorAlert.title',
    defaultMessage: '¡Algo va mal!'
  },
  moreInfoInstructions: {
    id: 'components.Modal.HttpErrorAlert.moreInfoInstructions',
    defaultMessage: 'Para más información contacta al administrador del sistema.'
  },
  error: {
    id: 'components.Modal.HttpErrorAlert.label.error',
    defaultMessage: 'Error'
  },
  errorCode: {
    id: 'components.Modal.HttpErrorAlert.errorCode',
    defaultMessage: 'x00000{errorCode}'
  },
  buttonOk: {
    id: 'components.Modal.HttpErrorAlert.buttonOk',
    defaultMessage: 'Aceptar'
  }
});