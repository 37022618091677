import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  notFoundTitle: {
    id: 'components.Table.Search.title.label',
    defaultMessage: 'Sin coincidencias'
  },
  notResultsFor: {
    id: 'components.Table.Search.Results.Empty',
    defaultMessage: 'No se encontraron resultados para '
  },
  empty: {
    id: 'components.Table.Search.Results.EmptyTable',
    defaultMessage: 'Sin registros'
  },
  notFoundRetry: {
    id: 'components.Table.Search.Results.retry',
    defaultMessage: 'Intente comprobar si hay errores tipográficos o utilice palabras completas.'
  },
  selectedTableItems: {
    id: 'components.Table.Toolbar.Selected.label',
    defaultMessage: 'seleccionados'
  },
  filterBy: {
    id: 'components.Table.Toolbar.Filter.label',
    defaultMessage: 'Filtrar por'
  },
  searchBarPlaceholder: {
    id: 'components.Table.Toolbar.Search.Placeholder',
    defaultMessage: 'Buscar...'
  },
  deleteTooltip: {
    id: 'components.Table.Toolbar.Action.Tooltip.Delete',
    defaultMessage: 'Eliminar'
  },
  filterListTooltip: {
    id: 'components.Table.Toolbar.Action.Tooltip.Filter',
    defaultMessage: 'Lista de filtros'
  },
  deleteRegister: {
    id: 'components.Table.Options.Action.Delete',
    defaultMessage: 'Eliminar'
  },
  editRegister: {
    id: 'components.Table.Options.Action.Edit',
    defaultMessage: 'Editar'
  }
});