import _get from "lodash/get";
import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from "../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    overflowX: 'auto',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1)
  },
  uploadImageButton: {
    backgroundColor: hexToRgba(theme.palette.text.primary, 0.07),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    minWidth: 90,
    height: 90,
    minHeight: 90,
    cursor: 'pointer',
    position: 'relative',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      '& $uploadIcon': {
        transform: 'translateY(-8px)'
      },
      '& $uploadText': {
        opacity: 1,
        marginBottom: -12
      }
    },
    '&.disabled': {
      cursor: 'auto',
      backgroundColor: hexToRgba(theme.palette.text.primary, 0.05),
      '& $uploadMoreIcon': {
        color: hexToRgba(theme.palette.text.primary, 0.5)
      }
    }
  },
  uploadIcon: _ref => {
    let color = _ref.color;
    return {
      fontSize: 45,
      transition: theme.transitions.create('transform'),
      color: _get(theme.palette, color)
    };
  },
  uploadText: _ref2 => {
    let color = _ref2.color;
    return {
      fontSize: '0.7rem',
      position: 'absolute',
      bottom: '25px',
      opacity: 0,
      transition: theme.transitions.create(['margin-bottom', 'opacity']),
      color: _get(theme.palette, color),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '95%'
    };
  },
  fileInput: {
    display: 'none'
  },
  uploadMoreIcon: _ref3 => {
    let color = _ref3.color;
    return {
      fontSize: 35,
      transition: theme.transitions.create('color'),
      color: _get(theme.palette, color)
    };
  }
}));
export { useStyles };