import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from "./ChartWrapperStyles";

const ChartWrapper = _ref => {
  let children = _ref.children,
      wrapperRef = _ref.wrapperRef;
  const classes = useStyles();
  return React.createElement("div", {
    className: classes.wrapper,
    ref: wrapperRef
  }, children);
};

export { ChartWrapper };