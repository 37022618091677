import PropTypes from 'prop-types';
const arrayOf = PropTypes.arrayOf,
      shape = PropTypes.shape,
      string = PropTypes.string,
      func = PropTypes.func,
      bool = PropTypes.bool,
      element = PropTypes.element,
      oneOfType = PropTypes.oneOfType,
      number = PropTypes.number,
      array = PropTypes.array,
      object = PropTypes.object,
      oneOf = PropTypes.oneOf;
const filtersType = arrayOf(shape({
  key: string.isRequired,
  value: string.isRequired
}));
const topTable = shape({
  title: string,
  addTitle: string,
  handleAdd: func
});
const columns = arrayOf(shape({
  key: oneOfType([string, number]).isRequired,
  label: string.isRequired,
  align: string
}).isRequired).isRequired;
const tableStyles = shape({
  tableContainer: string,
  tableHeadStyles: string,
  tableToolbarStyles: string,
  tableRowStyles: string
});
const selectedOptions = shape({
  checkboxHidden: bool,
  selectedIcon: element,
  selectedTooltip: object
});
export const menuOption = shape({
  title: string,
  onClick: func,
  disabled: bool,
  hidden: bool,
  icon: element
});
export const actions = shape({
  hiddenActions: bool,
  edit: menuOption,
  deleteAction: menuOption,
  customs: oneOfType([menuOption, arrayOf(menuOption)])
});
export const tableProps = {
  data: array.isRequired,
  columns: columns,
  mainKey: string,
  onSelectItems: func,
  configProps: shape({
    selectedOptions: selectedOptions,
    filters: filtersType,
    filterProp: string,
    topTable: topTable,
    actions: actions,
    tableStyles: tableStyles
  })
};
export const tableHeadProps = {
  order: oneOf(['asc', 'desc']),
  orderBy: string,
  rowCount: number,
  headLabel: columns,
  numSelected: number,
  onRequestSort: func,
  onSelectAllClick: func
};
export const tableToolbarProps = {
  numSelected: number,
  filteredValue: string,
  onFilter: func,
  onChangeFilterBy: func,
  filterProperty: string,
  filters: filtersType,
  onSelectItems: func,
  selectedOptions: selectedOptions,
  tableToolbarStyles: string
};