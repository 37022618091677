import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  formInstructions: {
    id: 'components.Auth.Login.formInstructions',
    defaultMessage: '¡Bienvenido!'
  },
  buttonSubmit: {
    id: 'components.Auth.Login.buttonSubmit',
    defaultMessage: 'Ingresar'
  },
  passwordRecoveryCaption: {
    id: 'components.Auth.Login.passwordRecoveryCaption',
    defaultMessage: 'Olvidé mi contraseña'
  },
  noAccountCaption: {
    id: 'components.Auth.Login.noAccountCaption',
    defaultMessage: '¿No tienes cuenta?'
  }
});