import _Typography from "@material-ui/core/esm/Typography";
import _useMediaQuery from "@material-ui/core/esm/useMediaQuery";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from "./GridChartStyles";
import { useEffect } from 'react';
import { useState } from 'react';
import { hexToRgba } from "../../helpers/colors";
import { Legend } from "./Legend/Legend";
const gridTemplateAreasExample = [['fabio', 'armando', 'armando', 'juvencio'], ['fabio', 'ricardo', 'carlos', 'miguel']];
const valuesExample = [{
  name: 'fabio',
  label: 'Fabio',
  value: 12
}, {
  name: 'armando',
  label: 'Armando',
  value: 20
}, {
  name: 'ricardo',
  label: 'Ricardo',
  value: 16
}, {
  name: 'carlos',
  label: 'Carlos',
  value: 8
}, {
  name: 'juvencio',
  label: 'Juvencio',
  value: 22
}, {
  name: 'miguel',
  label: 'Miguel',
  value: 13
}];

const GridChart = _ref => {
  let _ref$values = _ref.values,
      _values = _ref$values === void 0 ? valuesExample : _ref$values,
      _ref$gridTemplateArea = _ref.gridTemplateAreas,
      gridTemplateAreas = _ref$gridTemplateArea === void 0 ? gridTemplateAreasExample : _ref$gridTemplateArea,
      color = _ref.color,
      _ref$maxOpacity = _ref.maxOpacity,
      maxOpacity = _ref$maxOpacity === void 0 ? 0.6 : _ref$maxOpacity;

  const theme = useTheme();

  const xs = _useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles();

  const _useState = useState([]),
        _useState2 = _slicedToArray(_useState, 2),
        values = _useState2[0],
        setValues = _useState2[1];

  const legendContainerRef = useRef();

  const _useState3 = useState(0),
        _useState4 = _slicedToArray(_useState3, 2),
        legendContainerHeight = _useState4[0],
        setCaptionsContainerHeight = _useState4[1];

  useEffect(() => {
    setCaptionsContainerHeight(legendContainerRef.current.offsetHeight);
  }, []);
  useEffect(() => {
    const maxValue = _values.reduce((max, _ref2) => {
      let value = _ref2.value;
      return value > max ? value : max;
    }, _values[0].value);

    const minValue = _values.reduce((min, _ref3) => {
      let value = _ref3.value;
      return value < min ? value : min;
    }, _values[0].value);

    const numbersBetween = maxValue - minValue;
    const unit = maxOpacity / numbersBetween;
    setValues(_values.map(value => Object.assign(value, {
      opacity: unit * (value.value - minValue)
    })));
  }, [_values]);
  return _jsx("div", {
    className: classes.container
  }, void 0, _jsx("div", {
    className: classes.grid,
    style: {
      height: xs ? null : "calc(100% - ".concat(legendContainerHeight, "px)"),
      gridTemplateAreas: "'".concat(gridTemplateAreas.map(arr => arr.join(' ')).join("' '"), "'")
    }
  }, void 0, values.map((_ref4, index) => {
    let name = _ref4.name,
        label = _ref4.label,
        value = _ref4.value,
        opacity = _ref4.opacity;
    return _jsx("div", {
      className: classes.item,
      style: {
        gridArea: name,
        backgroundColor: hexToRgba(color || theme.palette.primary.main, opacity)
      }
    }, "".concat(index, "-").concat(name, "-GridChartItem"), _jsx(_Typography, {
      className: classes.itemLabel
    }, void 0, label));
  })), _jsx(Legend, {
    legendContainerRef: legendContainerRef,
    color: color || theme.palette.primary.main,
    maxOpacity: maxOpacity
  }));
};

export { GridChart };