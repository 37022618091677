import * as Yup from 'yup';
import { messages } from "./RegisterFormMessages";
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#,.:;/])[A-Za-z\d@$!%*?&#,.:;/]{8,}$/;
export const initialValues = {
  speciality: '',
  professionalId: '',
  name: '',
  email: '',
  state: '',
  phone: '',
  hospital: '',
  password: '',
  passwordConfirmation: '',
  privacy: false,
  image: null
};
export const getValidationSchema = intl => {
  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const emailErrorMessage = intl.formatMessage(messages.emailError);
  const matchErrorMessage = intl.formatMessage(messages.matchError);
  const privacyErrorMessage = intl.formatMessage(messages.privacyError);
  const passwordRegexError = intl.formatMessage(messages.passwordRegexError);
  return Yup.object({
    speciality: Yup.string().required(requiredErrorMessage),
    professionalId: Yup.string().required(requiredErrorMessage),
    name: Yup.string().required(requiredErrorMessage),
    email: Yup.string().email(emailErrorMessage).required(requiredErrorMessage),
    state: Yup.string().required(requiredErrorMessage),
    phone: Yup.string(),
    hospital: Yup.string(),
    password: Yup.string().required(requiredErrorMessage).matches(passwordRegex, passwordRegexError),
    passwordConfirmation: Yup.string().required(requiredErrorMessage).oneOf([Yup.ref('password'), null], matchErrorMessage),
    privacy: Yup.bool().oneOf([true], privacyErrorMessage),
    image: Yup.object().shape({
      url: Yup.string()
    }).nullable().required(requiredErrorMessage)
  });
};