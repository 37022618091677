import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%'
  },
  grid: {
    width: '100%',
    display: 'grid',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: 55
    }
  },
  itemLabel: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    textAlign: 'center'
  }
}));
export { useStyles };