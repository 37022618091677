import { useIntl } from 'react-intl';
import { messages } from "../ProfileImageSectionMessages";
import ImageIcon from '@material-ui/icons/AccountCircleRounded';
export const useFields = () => {
  const intl = useIntl();
  return [{
    type: 'image',
    name: 'image',
    breakpoints: {
      xs: 12
    },
    icon: ImageIcon,
    label: intl.formatMessage(messages.imageLabel),
    multiple: false,
    color: 'primary.main'
  }];
};