import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  item: {
    color: theme.palette.text.secondary
  },
  menu: {
    width: 200,
    maxWidth: '100%'
  }
}));
export { useStyles };