import _get from "lodash/get";
import { useIntl } from 'react-intl';
import { messages } from "../DataSectionMessages";
export const useFields = _ref => {
  let data = _ref.data;
  const intl = useIntl();
  const inputProps = {
    fullWidth: true
  };
  const requiredFields = [Object.assign({
    name: 'speciality',
    label: intl.formatMessage(messages.specialityLabel),
    placeholder: intl.formatMessage(messages.specialityPlaceholder),
    type: 'select',
    options: data.speciality,
    getOptionLabel: option => _get(option, 'name', ''),
    getOptionValue: option => _get(option, '_id', ''),
    getOptionSelected: (option, value) => option === value,
    breakpoints: {
      sm: 6,
      xs: 12
    }
  }, inputProps), Object.assign({
    name: 'professionalId',
    label: intl.formatMessage(messages.professionalIdLabel),
    placeholder: intl.formatMessage(messages.professionalIdPlaceholder),
    type: 'text',
    breakpoints: {
      sm: 6,
      xs: 12
    }
  }, inputProps), Object.assign({
    name: 'name',
    label: intl.formatMessage(messages.nameLabel),
    placeholder: intl.formatMessage(messages.namePlaceholder),
    type: 'text',
    breakpoints: {
      xs: 12
    }
  }, inputProps), Object.assign({
    name: 'email',
    label: intl.formatMessage(messages.emailLabel),
    placeholder: intl.formatMessage(messages.emailPlaceholder),
    type: 'text',
    breakpoints: {
      sm: 6,
      xs: 12
    }
  }, inputProps), Object.assign({
    name: 'state',
    label: intl.formatMessage(messages.stateLabel),
    placeholder: intl.formatMessage(messages.statePlaceholder),
    type: 'select',
    options: data.states,
    getOptionLabel: option => _get(option, 'name', ''),
    getOptionValue: option => _get(option, 'code', ''),
    getOptionSelected: (option, value) => option === value,
    breakpoints: {
      sm: 6,
      xs: 12
    }
  }, inputProps)];
  const optionalFields = [Object.assign({
    name: 'phone',
    label: intl.formatMessage(messages.phoneLabel),
    placeholder: intl.formatMessage(messages.phonePlaceholder),
    type: 'text',
    breakpoints: {
      sm: 6,
      xs: 12
    }
  }, inputProps), Object.assign({
    name: 'hospital',
    label: intl.formatMessage(messages.hospitalLabel),
    placeholder: intl.formatMessage(messages.hospitalPlaceholder),
    type: 'text',
    breakpoints: {
      sm: 6,
      xs: 12
    }
  }, inputProps)];
  return {
    requiredFields,
    optionalFields
  };
};