import _Button from "@material-ui/core/esm/Button";
import _Grid from "@material-ui/core/esm/Grid";
import _Typography from "@material-ui/core/esm/Typography";
const _excluded = ["fields", "initialValues", "validationRules", "handleSubmit", "buttonSubmitLabel", "error", "ignoreInputErrorStyle", "disableSubmitOnFormInvalid", "validateOnMount", "inputProps", "buttonSubmitProps", "withInputsBackground", "withInputsBorder", "autoFocus", "showSubmitButton", "sections", "formikRef"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as types from "./types";
import { useStyles } from "./FormStyles";
import { renderAutocomplete, renderCheckbox, renderDatePicker, renderImagePicker, renderRadioGroup, renderSwitch, renderTextField, renderTimePicker } from "./renderers";
import { usePasswordInputs } from "./hooks/usePasswordInputs";
import { getFieldObject } from "./helpers";

const Form = props => {
  const classes = useStyles();

  const fields = props.fields,
        initialValues = props.initialValues,
        validationRules = props.validationRules,
        handleSubmit = props.handleSubmit,
        buttonSubmitLabel = props.buttonSubmitLabel,
        error = props.error,
        ignoreInputErrorStyle = props.ignoreInputErrorStyle,
        disableSubmitOnFormInvalid = props.disableSubmitOnFormInvalid,
        validateOnMount = props.validateOnMount,
        _props$inputProps = props.inputProps,
        inputProps = _props$inputProps === void 0 ? {} : _props$inputProps,
        buttonSubmitProps = props.buttonSubmitProps,
        withInputsBackground = props.withInputsBackground,
        withInputsBorder = props.withInputsBorder,
        autoFocus = props.autoFocus,
        _props$showSubmitButt = props.showSubmitButton,
        showSubmitButton = _props$showSubmitButt === void 0 ? true : _props$showSubmitButt,
        _props$sections = props.sections,
        sections = _props$sections === void 0 ? [{
    name: 'main'
  }] : _props$sections,
        formikRef = props.formikRef,
        otherProps = _objectWithoutProperties(props, _excluded);

  const formik = useFormik({
    initialValues,
    validationSchema: validationRules,
    onSubmit: handleSubmit
  });
  const passwordInputs = usePasswordInputs({
    fields,
    formik
  });
  useEffect(() => {
    if (validateOnMount) formik.validateForm();
    if (!!formikRef) formikRef.current = formik;
  }, []);
  return _jsx(MuiPickersUtilsProvider, {
    utils: DateFnsUtils
  }, void 0, React.createElement("form", _extends({
    onSubmit: formik.handleSubmit,
    className: "".concat(classes.form, " ").concat(withInputsBackground ? classes.withInputBackground : '', " ").concat(withInputsBorder ? '' : classes.withoutInputBorder)
  }, otherProps), sections.map((_ref, index) => {
    let name = _ref.name,
        title = _ref.title,
        description = _ref.description;
    return React.createElement(React.Fragment, null, index !== 0 && _jsx("div", {
      className: classes.sectionSpacer
    }), title && React.createElement(_Typography, _extends({
      component: "p",
      variant: "h6"
    }, title), title.text), description && React.createElement(_Typography, _extends({
      variant: "body2",
      color: "textSecondary"
    }, description), description.text), (title || description) && _jsx("div", {
      className: classes.innerSectionSpacer
    }), _jsx(_Grid, {
      container: true,
      spacing: 1
    }, void 0, fields.filter(field => (getFieldObject(field, formik).section || 'main') === name).map((_field, index) => {
      const field = getFieldObject(_field, formik);
      const isMultiSelect = field.type === 'multiselect';
      return React.createElement(_Grid, _extends({
        item: true
      }, field.breakpoints || types.defaultProps.breakpoints), (field.type === 'select' || isMultiSelect) && renderAutocomplete(_objectSpread({
        index,
        formik,
        field
      }, props)), ['text', 'password', 'textarea'].includes(field.type) && renderTextField(_objectSpread({
        index,
        formik,
        field,
        passwordInputs
      }, props)), field.type === 'switch' && renderSwitch({
        index,
        formik,
        field
      }), field.type === 'checkbox' && renderCheckbox({
        index,
        formik,
        field
      }), field.type === 'radioGroup' && renderRadioGroup({
        index,
        formik,
        field
      }), field.type === 'image' && renderImagePicker({
        index,
        formik,
        field
      }), field.type === 'datePicker' && renderDatePicker(_objectSpread({
        index,
        formik,
        field
      }, props)), field.type === 'timePicker' && renderTimePicker(_objectSpread({
        index,
        formik,
        field
      }, props)));
    })));
  }), showSubmitButton && React.createElement(_Button, _extends({
    type: "submit",
    disabled: disableSubmitOnFormInvalid && !formik.isValid
  }, buttonSubmitProps, {
    className: classes.submitButton
  }), _jsx(_Typography, {
    variant: "body1",
    color: buttonSubmitProps.labelColor || 'textPrimary'
  }, void 0, buttonSubmitLabel)), error && _jsx("p", {
    className: classes.submitError
  }, void 0, error)));
};

Form.defaultProps = {
  inputProps: types.defaultProps.inputType,
  buttonSubmitProps: types.defaultProps.buttonType,
  withInputsBackground: true,
  withInputsBorder: false,
  autoFocus: false
};
export { Form };