import _TextField from "@material-ui/core/esm/TextField";
const _excluded = ["field", "inputProps", "error", "helperText", "onChange", "options", "value", "multiple", "getOptionLabel", "getOptionSelected", "getOptionValue"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as AutocompleteMUI } from '@material-ui/lab';
import * as types from "../types";
import { defaultGetOptionLabel } from "./helpers";
import { useStyles } from "./AutocompleteStyles";

const Autocomplete = props => {
  const field = props.field,
        inputProps = props.inputProps,
        error = props.error,
        helperText = props.helperText,
        _onChange = props.onChange,
        options = props.options,
        value = props.value,
        multiple = props.multiple,
        getOptionLabel = props.getOptionLabel,
        getOptionSelected = props.getOptionSelected,
        _props$getOptionValue = props.getOptionValue,
        getOptionValue = _props$getOptionValue === void 0 ? option => option.name : _props$getOptionValue,
        otherProps = _objectWithoutProperties(props, _excluded);

  const classes = useStyles();

  const _useState = useState(value),
        _useState2 = _slicedToArray(_useState, 2),
        selectedValue = _useState2[0],
        setSelectedValue = _useState2[1];

  const _useState3 = useState(multiple ? [] : ''),
        _useState4 = _slicedToArray(_useState3, 2),
        inputValue = _useState4[0],
        setInputValue = _useState4[1];

  useEffect(() => {
    setSelectedValue(multiple ? options.filter(option => value.includes(getOptionValue(option))) : options.find(option => getOptionValue(option) === value));
  }, [value, options]);
  if (!options) return null;
  return React.createElement(AutocompleteMUI, _extends({}, otherProps, {
    options: options,
    id: field.id,
    classes: {
      paper: classes.menu
    },
    multiple: multiple,
    value: selectedValue,
    onChange: (_, newValue) => {
      try {
        _onChange(field.name, multiple ? newValue.map(value => getOptionValue(value)) : getOptionValue(newValue));
      } catch (_unused) {
        _onChange(field.name, multiple ? [] : '');
      }
    },
    inputValue: inputValue,
    onInputChange: (_, newInputValue) => setInputValue(newInputValue),
    getOptionLabel: getOptionLabel,
    getOptionSelected: getOptionSelected,
    renderInput: params => React.createElement(_TextField, _extends({}, params, inputProps, field.textFieldProps, {
      disabled: field.disabled,
      id: field.id,
      name: field.name,
      label: field.label,
      type: field.type,
      placeholder: field.placeholder,
      error: error,
      helperText: helperText
    }))
  }));
};

Autocomplete.defaultProps = {
  // inputProps: types.defaultProps.inputType,
  // buttonSubmitProps: types.defaultProps.buttonType,
  getOptionLabel: defaultGetOptionLabel,
  getOptionSelected: () => {},
  onChange: () => {}
};
Autocomplete.props = {
  options: PropTypes.array.isRequired,
  field: types.fieldType,
  inputProps: types.inputType,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  limitTags: PropTypes.number,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  getOptionSelected: PropTypes.func
};
export { Autocomplete };