import _get from "lodash/get";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    transition: theme.transitions.create(['opacity', 'margin-right']),
    opacity: 0,
    marginRight: 0,
    '&.visible': {
      opacity: 1,
      marginRight: theme.spacing(0.5)
    }
  },
  avatar: {
    transition: theme.transitions.create('width'),
    height: 90,
    width: 0,
    '&.visible': {
      width: 90
    }
  },
  removeButton: _ref => {
    let color = _ref.color;
    return {
      position: 'absolute',
      zIndex: 1,
      right: 9,
      top: 9 - theme.spacing(0.5),
      width: 15,
      height: 15,
      backgroundColor: _get(theme.palette, color)
    };
  },
  removeIcon: _ref2 => {
    let color = _ref2.color;
    return {
      color: theme.palette.getContrastText(_get(theme.palette, color)),
      fontSize: 13
    };
  }
}));
export { useStyles };