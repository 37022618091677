import AWS from 'aws-sdk';
import { v4 as uuid } from 'uuid';
import { config } from "../providers/config";
AWS.config.update({
  accessKeyId: config.aws.accessKeyId,
  secretAccessKey: config.aws.secretAccessKey
});
const bucket = new AWS.S3({
  params: {
    Bucket: config.aws.bucketName,
    region: config.aws.region
  },
  httpOptions: {
    timeout: 3000000
  }
});
export const uploadFile = _ref => {
  let file = _ref.file,
      onProgressChange = _ref.onProgressChange,
      onError = _ref.onError,
      onSuccess = _ref.onSuccess;
  const fileName = "".concat(uuid(), "-").concat(file.name);
  bucket.putObject({
    ACL: 'public-read',
    Body: file,
    Bucket: config.aws.bucketName,
    Key: fileName,
    ContentType: file.type
  }, error => {
    if (error) {
      onError && onError(error);
    } else {
      onSuccess && onSuccess("".concat(config.aws.bucketUrl, "/").concat(fileName));
    }
  }).on('httpUploadProgress', _ref2 => {
    let loaded = _ref2.loaded,
        total = _ref2.total;
    return onProgressChange && onProgressChange(Math.round(loaded / total * 100));
  });
};