import _Box from "@material-ui/core/esm/Box";
import _Typography from "@material-ui/core/esm/Typography";
import _Grid from "@material-ui/core/esm/Grid";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./DataSectionMessages";
import { useFields } from "./hooks/useFields";
import { renderAutocomplete, renderTextField } from '@octopy/react-form';

const DataSection = _ref => {
  let formik = _ref.formik,
      aditionalData = _ref.aditionalData;
  const intl = useIntl();

  const _useFields = useFields({
    data: aditionalData
  }),
        requiredFields = _useFields.requiredFields,
        optionalFields = _useFields.optionalFields;

  const fieldsMapper = (field, index) => React.createElement(_Grid, _extends({
    key: "data-section-field-".concat(index),
    item: true
  }, field.breakpoints), field.type === 'select' && renderAutocomplete({
    index,
    formik,
    field
  }), field.type === 'text' && renderTextField({
    index,
    formik,
    field
  }));

  return React.createElement(React.Fragment, null, _jsx(_Typography, {
    variant: "subtitle1",
    color: "primary"
  }, void 0, intl.formatMessage(messages.requiredSectionDescription)), _jsx(_Box, {
    pt: 1.5,
    pb: 3
  }, void 0, _jsx(_Grid, {
    container: true,
    spacing: 1
  }, void 0, requiredFields.map(fieldsMapper))), _jsx(_Typography, {
    variant: "subtitle1",
    color: "primary"
  }, void 0, intl.formatMessage(messages.optionalSectionDescription)), _jsx(_Box, {
    pt: 1.5,
    pb: 1
  }, void 0, _jsx(_Grid, {
    container: true,
    spacing: 1
  }, void 0, optionalFields.map(fieldsMapper))));
};

export { DataSection };