import _Box from "@material-ui/core/esm/Box";
import _Typography from "@material-ui/core/esm/Typography";
const _excluded = ["searchQuery"];
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./TableMessages"; // material

function SearchNotFound(_ref) {
  let _ref$searchQuery = _ref.searchQuery,
      searchQuery = _ref$searchQuery === void 0 ? '' : _ref$searchQuery,
      other = _objectWithoutProperties(_ref, _excluded);

  const _useIntl = useIntl(),
        f = _useIntl.formatMessage;

  return React.createElement(_Box, other, _jsx(_Typography, {
    gutterBottom: true,
    align: "center",
    variant: "body1",
    style: {
      fontWeight: 'bold'
    }
  }, void 0, f(searchQuery !== '' ? messages.notFoundTitle : messages.empty)), _jsx(_Typography, {
    variant: "body2",
    align: "center"
  }, void 0, searchQuery !== '' && React.createElement(React.Fragment, null, f(messages.notResultsFor), _jsx("strong", {}, void 0, "\"", searchQuery, "\""), ".", f(messages.notFoundRetry))));
}

export { SearchNotFound };