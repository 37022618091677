import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      '& div:first-of-type': {
        borderRadius: theme.spacing(0.5)
      },
      '& p.Mui-error': {// position: 'absolute',
        // bottom: -theme.spacing(1.5)
      }
    }
  },
  withInputBackground: {
    '& .MuiFormControl-root, .MuiFilledInput-root': {
      backgroundColor: theme.palette.background.paper
    }
  },
  withoutInputBorder: {
    '& .MuiOutlinedInput-root, .MuiFilledInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background.paper
      },
      '&::before': {
        borderBottom: 'none'
      }
    }
  },
  submitError: {
    textAlign: 'center',
    color: theme.palette.warning.light,
    fontSize: theme.typography.body2.fontSize
  },
  submitButton: {
    '& p': {
      fontWeight: 500,
      color: theme.palette.common.white
    }
  },
  sectionSpacer: {
    height: theme.spacing(2)
  },
  innerSectionSpacer: {
    height: theme.spacing(1)
  }
}));
export { useStyles };