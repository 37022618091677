import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  imageLabel: {
    id: 'components.Register.RegisterForm.ProfileImageSection.imageLabel',
    defaultMessage: 'Subir imagen'
  },
  sectionDescription: {
    id: 'components.Register.RegisterForm.ProfileImageSection.sectionDescription',
    defaultMessage: 'Selecciona una foto:'
  }
});