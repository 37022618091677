import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  privacyCheckboxLabel: {
    color: theme.palette.text.secondary,
    textTransform: 'initial',
    '& .MuiButton-label': {
      lineHeight: 1,
      textDecoration: 'underline'
    }
  }
}));
export { useStyles };