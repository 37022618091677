import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      '& div:first-of-type': {
        borderRadius: theme.spacing(0.5)
      },
      '& p.Mui-error': {// position: 'absolute',
        // bottom: -theme.spacing(1.5)
      }
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  sectionWrapper: {
    '&.marginAlways': {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  }
}));
export { useStyles };