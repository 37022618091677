import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from "../../utils/theme";
const useStyles = makeStyles(theme => ({
  tableCardContainer: {
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    borderRadius: '16px'
  },
  cellNotFound: {
    padding: theme.spacing(3, 2)
  },
  selected: {
    '&$selected': {
      backgroundColor: hexToRgba(theme.palette.primary.main, 0.07),
      '&$selected:hover': {
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.1)
      }
    }
  }
}));
export { useStyles };