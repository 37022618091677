import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rightPosition: {
    marginLeft: 'auto'
  }
}));
export { useStyles };