import _Container from "@material-ui/core/esm/Container";
import _Button from "@material-ui/core/esm/Button";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./RegisterMessages";
import { useStyles } from "./RegisterStyles";
import { RegisterForm } from "./RegisterForm";

const Register = props => {
  const aditionalData = props.aditionalData,
        actions = props.actions,
        formikRef = props.formikRef;
  const intl = useIntl();
  const classes = useStyles();
  return _jsx(_Container, {
    className: classes.container
  }, void 0, _jsx(RegisterForm, {
    formikRef: formikRef,
    aditionalData: aditionalData,
    handleSubmit: actions.handleSubmit
  }), _jsx(_Button, {
    className: classes.submitButton,
    size: "large",
    color: "secondary",
    onClick: () => formikRef.current.submitForm()
  }, void 0, intl.formatMessage(messages.send)));
};

export { Register };