import _Box from "@material-ui/core/esm/Box";
import _Button from "@material-ui/core/esm/Button";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useModal } from "../Modal/hooks/useModal";
import { messages } from "./ConfirmationDeleteMessages";
import { useStyles } from "./ConfirmationDeleteStyles";

const ConfirmationDelete = props => {
  const handleDelete = props.handleDelete;

  const _useIntl = useIntl(),
        f = _useIntl.formatMessage;

  const classes = useStyles();

  const _useModal = useModal(),
        handleCloseModal = _useModal.handleCloseModal;

  return _jsx(_Box, {
    className: classes.container,
    justifyContent: "flex-end"
  }, void 0, _jsx(_Button, {
    variant: "outlined",
    color: "default",
    onClick: handleCloseModal
  }, void 0, f(messages.cancel)), _jsx(_Button, {
    variant: "contained",
    color: "primary",
    onClick: handleDelete,
    className: classes.margin
  }, void 0, f(messages.delete)));
};

export { ConfirmationDelete };