import { makeStyles } from '@material-ui/core/styles';
import { Images } from "../../assets";
const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '1px',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(1),
      height: 'auto'
    }
  },
  leftContainer: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  groupImage: {
    marginRight: '22%',
    width: '68%'
  },
  legend: {
    marginRight: '22%',
    width: '68%',
    minHeight: '8%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  legendText: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center'
  },
  companyLogoImage: {
    margin: theme.spacing(4),
    marginBottom: 'auto',
    marginRight: 'auto'
  },
  background: {
    position: 'absolute',
    bottom: 0,
    left: -500,
    right: 0,
    top: 0,
    zIndex: -1,
    transform: 'skew(15deg) translateX(-7%)',
    backgroundColor: theme.palette.primary.main
  },
  rightContainer: {
    minWidth: '33%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0
    }
  },
  formContainer: {
    maxWidth: theme.typography.pxToRem(340),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  productLogo: {
    width: 337,
    height: 56,
    maxWidth: '95%',
    maskImage: "url(\"".concat(Images.defaultProductLogo, "\")"),
    maskSize: '100%',
    maskRepeat: 'no-repeat',
    maskPosition: 'center',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.text.primary : theme.palette.primary.main
  },
  instructions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '2.425rem'
  },
  greetingsImage: {
    height: theme.typography.pxToRem(234),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      height: theme.typography.pxToRem(180),
      marginTop: theme.spacing(2)
    }
  },
  passwordRecoveryLink: {
    marginTop: theme.spacing(1),
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'right',
    marginRight: theme.spacing(1),
    width: '100%',
    '& span': {
      margin: theme.spacing(1)
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark
    }
  },
  noAccountLink: {
    marginTop: theme.spacing(2),
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    '& span': {
      margin: theme.spacing(1)
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark
    }
  },
  submitButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
export { useStyles };