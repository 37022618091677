import _Grid from "@material-ui/core/esm/Grid";
import _useMediaQuery from "@material-ui/core/esm/useMediaQuery";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { messages } from "./RegisterFormMessages";
import { useStyles } from "./RegisterFormStyles";
import { Card } from "./Card";
import { Images } from "../../../assets";
import { getValidationSchema, initialValues } from "./helpers";
import { DataSection } from "./DataSection";
import { PasswordSection } from "./PasswordSection/PasswordSection";
import { ProfileImageSection } from "./ProfileImageSection/ProfileImageSection";
import { useEffect } from 'react';

const RegisterForm = _ref => {
  let formikRef = _ref.formikRef,
      aditionalData = _ref.aditionalData,
      handleSubmit = _ref.handleSubmit;
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();

  const smDown = _useMediaQuery(theme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(intl),
    onSubmit: handleSubmit
  });
  useEffect(() => formikRef.current = formik, []);
  return _jsx(_Grid, {
    container: true,
    spacing: smDown ? 0 : 2,
    className: classes.container
  }, void 0, _jsx(_Grid, {
    item: true,
    md: 6,
    xs: 12,
    className: classes.sectionWrapper
  }, void 0, _jsx(Card, {
    title: intl.formatMessage(messages.data),
    icon: Images.pen,
    direction: "left"
  }, void 0, _jsx(DataSection, {
    formik: formik,
    aditionalData: aditionalData
  }))), _jsx(_Grid, {
    item: true,
    container: true,
    direction: "column",
    md: 6,
    xs: 12,
    spacing: 0
  }, void 0, _jsx(_Grid, {
    item: true,
    className: classes.flexGrow,
    className: "".concat(classes.sectionWrapper, " marginAlways")
  }, void 0, _jsx(Card, {
    title: intl.formatMessage(messages.password),
    icon: Images.key,
    direction: "right"
  }, void 0, _jsx(PasswordSection, {
    formik: formik
  }))), _jsx(_Grid, {
    item: true,
    className: classes.flexGrow,
    className: classes.sectionWrapper
  }, void 0, _jsx(Card, {
    title: intl.formatMessage(messages.profileImage),
    icon: Images.accountCircle,
    direction: "right"
  }, void 0, _jsx(ProfileImageSection, {
    formik: formik
  })))));
};

export { RegisterForm };