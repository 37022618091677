import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  formInstructions: {
    id: 'components.Auth.Login.formInstructions',
    defaultMessage: '¡Bienvenido!'
  },
  buttonSubmit: {
    id: 'components.Auth.Login.buttonSubmit',
    defaultMessage: 'Ingresar'
  },
  passwordRecoveryCaption: {
    id: 'components.Auth.Login.passwordRecoveryCaption',
    defaultMessage: 'Olvidé mi contraseña'
  },
  noAccountCaption: {
    id: 'components.Auth.Login.noAccountCaption',
    defaultMessage: '¿No tienes una cuenta? Crea una aqui'
  },
  access: {
    id: 'components.Auth.Login.access',
    defaultMessage: 'Ingresar'
  },
  legend1: {
    id: 'components.Auth.login.legend1',
    defaultMessage: 'Material desarrollado con fines de educación médica continua.'
  },
  legend2: {
    id: 'components.Auth.login.legend2',
    defaultMessage: 'Material exclusivo para profesionales de la salud.'
  }
});