import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: _ref => {
    let direction = _ref.direction;
    return {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1.5),
      borderTopLeftRadius: theme.shape.borderRadius * (direction === 'left' ? 2 : 0.5),
      borderBottomLeftRadius: theme.shape.borderRadius * (direction === 'left' ? 2 : 0.5),
      borderTopRightRadius: theme.shape.borderRadius * (direction === 'right' ? 2 : 0.5),
      borderBottomRightRadius: theme.shape.borderRadius * (direction === 'right' ? 2 : 0.5),
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.shape.borderRadius * 2
      }
    };
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  headerImage: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(1)
  }
}));
export { useStyles };