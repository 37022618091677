import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from "../../../helpers/colors";
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  barWrapper: {
    width: 316,
    height: 12,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only('xs')]: {
      width: '90%'
    }
  },
  bar: _ref => {
    let color = _ref.color,
        maxOpacity = _ref.maxOpacity;
    return {
      width: '100%',
      height: '100%',
      background: "linear-gradient(90deg, ".concat(theme.palette.common.white, " 0%, ").concat(hexToRgba(color, maxOpacity), " 100%)")
    };
  },
  captionsContainer: {
    width: 356,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      width: '95%'
    }
  },
  caption: {
    color: theme.palette.type === 'dark' ? theme.palette.text.hint : theme.palette.text.secondary
  }
}));
export { useStyles };