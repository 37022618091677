import _Button from "@material-ui/core/esm/Button";
import _Typography from "@material-ui/core/esm/Typography";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useModal } from "../Modal/hooks/useModal";
import { messages } from "./AlertMessages";
import { useStyles } from "./AlertStyles";

const Alert = props => {
  const intl = useIntl();
  const classes = useStyles();

  const _useModal = useModal(),
        handleCloseModal = _useModal.handleCloseModal;

  const message = props.message,
        onAccept = props.onAccept,
        onCancel = props.onCancel;

  const handleClickOnAccept = () => {
    handleCloseModal();
    if (onAccept) onAccept();
  };

  const handleClickOnCancel = () => {
    handleCloseModal();
    if (onCancel) onCancel();
  };

  return _jsx("div", {
    className: classes.container
  }, void 0, _jsx(_Typography, {
    variant: "body1",
    className: classes.message
  }, void 0, message), _jsx("div", {
    className: classes.buttons
  }, void 0, onCancel && _jsx(_Button, {
    color: "primary",
    onClick: handleClickOnCancel
  }, void 0, intl.formatMessage(messages.buttonCancel)), _jsx(_Button, {
    color: "primary",
    onClick: handleClickOnAccept
  }, void 0, intl.formatMessage(messages.buttonOk))));
};

export { Alert };