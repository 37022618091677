import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  hotZone: {
    id: 'components.Legend.hotZone',
    defaultMessage: 'Zona Caliente'
  },
  coldZone: {
    id: 'components.Legend.coldZone',
    defaultMessage: 'Zona Fria'
  }
});