import _Card from "@material-ui/core/esm/Card";
import _Typography from "@material-ui/core/esm/Typography";
import _useMediaQuery from "@material-ui/core/esm/useMediaQuery";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from "./CardStyles";

const Card = _ref => {
  let children = _ref.children,
      title = _ref.title,
      icon = _ref.icon,
      direction = _ref.direction;
  const classes = useStyles({
    direction
  });
  const theme = useTheme();

  const smDown = _useMediaQuery(theme.breakpoints.down('sm'));

  return _jsx(_Card, {
    className: classes.container
  }, void 0, _jsx("div", {
    className: classes.header
  }, void 0, _jsx("img", {
    src: icon,
    alt: "icon",
    className: classes.headerImage
  }), _jsx(_Typography, {
    variant: smDown ? 'h6' : 'h4',
    color: "primary"
  }, void 0, title)), children);
};

export { Card };