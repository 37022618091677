import _Button from "@material-ui/core/esm/Button";
import _Box from "@material-ui/core/esm/Box";
import _Typography from "@material-ui/core/esm/Typography";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from "./HttpErrorAlertMessages";
import { useStyles } from "./HttpErrorAlertStyles";

const HttpErrorAlert = props => {
  const intl = useIntl();
  const classes = useStyles();
  const errorCode = props.errorCode,
        errorMessage = props.errorMessage,
        onAccept = props.onAccept;
  return React.createElement(React.Fragment, null, _jsx(_Box, {
    display: "flex",
    flexDirection: "column",
    className: "modal-error"
  }, void 0, _jsx(_Box, {}, void 0, _jsx(_Typography, {
    variant: "h5",
    color: "primary",
    className: classes.title
  }, void 0, intl.formatMessage(messages.title)), errorMessage && _jsx(_Typography, {
    variant: "body1"
  }, void 0, errorMessage), _jsx(_Typography, {
    variant: "body2"
  }, void 0, intl.formatMessage(messages.moreInfoInstructions)), _jsx("div", {
    className: classes.errorCode
  }))), _jsx(_Box, {
    mt: 2,
    mb: 1,
    textAlign: "right"
  }, void 0, _jsx(_Button, {
    color: "primary",
    onClick: onAccept
  }, void 0, intl.formatMessage(messages.buttonOk))));
};

export { HttpErrorAlert };