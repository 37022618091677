import _MenuItem from "@material-ui/core/esm/MenuItem";
import _ListItemText from "@material-ui/core/esm/ListItemText";
import _ListItemIcon from "@material-ui/core/esm/ListItemIcon";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import { menuOption } from "../types";
import { useStyles } from "./TableOptionsStyles";

function Option(props) {
  const item = props.item,
        text = props.text,
        onClick = props.onClick,
        hidden = props.hidden,
        disabled = props.disabled,
        icon = props.icon,
        setIsOpen = props.setIsOpen;
  const classes = useStyles();

  const handleClick = () => {
    setIsOpen(false);
    if (onClick) return onClick(item);
  };

  if (hidden) return null;
  return _jsx(_MenuItem, {
    className: classes.item,
    disabled: disabled,
    onClick: handleClick
  }, void 0, icon && _jsx(_ListItemIcon, {}, void 0, _jsx(props.icon, {})), _jsx(_ListItemText, {
    primary: text,
    primaryTypographyProps: {
      variant: 'body2'
    }
  }));
}

Option.defaultProps = {
  disabled: false,
  hidden: false
};
export { Option };