import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  passwordLabel: {
    id: 'components.Register.RegisterForm.PasswordSection.passwordLabel',
    defaultMessage: 'Contraseña'
  },
  passwordPlaceholder: {
    id: 'components.Register.RegisterForm.PasswordSection.passwordPlaceholder',
    defaultMessage: 'Introduce una contraseña'
  },
  passwordConfirmationLabel: {
    id: 'components.Register.RegisterForm.PasswordSection.passwordConfirmationLabel',
    defaultMessage: 'Repetir contraseña'
  },
  passwordConfirmationPlaceholder: {
    id: 'components.Register.RegisterForm.PasswordSection.passwordConfirmationPlaceholder',
    defaultMessage: 'Repite la contraseña'
  },
  privacyLabel: {
    id: 'components.Register.RegisterForm.PasswordSection.privacyLabel',
    defaultMessage: 'Aviso de privacidad'
  },
  sectionDescription: {
    id: 'components.Register.RegisterForm.PasswordSection.sectionDescription',
    defaultMessage: 'Debe contar con al menos 8 caracteres, una letra mayúscula, un número y un signo.'
  },
  privacyModalTitle: {
    id: 'components.Register.RegisterForm.PasswordSection.privacyModalTitle',
    defaultMessage: 'Aviso de privacidad'
  },
  privacyModalBody: {
    id: 'components.Register.RegisterForm.PasswordSection.privacyModalBody',
    defaultMessage: "En Novo Nordisk México, SA de CV, estamos comprometidos en garantizar que todos nuestros procesos tengan una mejora continua con un impacto positivo en beneficio de los pacientes. Por este motivo, Novo Nordisk México ha desarrollado este consentimiento informado para compartirle los canales por los cuales lo estaríamos contactando: {br}{br}-Visita médica presencial o remota {br}-Llamada telefónica o video conferencia personalizada {br}-Mensajería instantánea a su teléfono móvil (whatsapp, teams, u otras plataformas de mensajería) {br}-Webinars o talleres científicos por medios electrónicos {br}-Email {br}-Página web dedicada a profesionales de la salud {br}-Visita del equipo de educadoras del programa de apoyo a pacientes {br}{br}Estos medios de contacto no implican ninguna obligación de prescripción ni tampoco constituye una obligación para la Compañía. Novo Nordisk México, S.A. de C.V. y sus empresas afiliadas y subsidiarias (en lo sucesivo conjuntamente 'Novo Nordisk'), le comunican que, utilizará sus datos personales recabados para fines estadísticos en relación con los servicios prestados y productos fabricados y comercializados por Novo Nordisk. Para mayor información sobre el tratamiento y de los derechos que puede a ver valer, usted puede acceder al aviso de privacidad integral a través de https://www.novonordisk.com.mx/legal-rights-and-pricacy.html En caso de que usted quiera ejercer sus derechos ARCO conforme a la Ley de Protección de Datos en Posesión de Particulares favor escribir un correo electrónico a datospersonales@novonordisk.com"
  }
});