import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    width: 500,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  margin: {
    [theme.breakpoints.up('xs')]: {
      marginLeft: 20
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  }
}));
export { useStyles };