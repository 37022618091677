import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2)
  },
  containerForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: theme.typography.pxToRem(450),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1)
    }
  },
  productLogo: {
    // height: theme.typography.pxToRem(110),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  instructions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center'
  },
  loginLink: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '& p': {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center'
    },
    '& span': {
      margin: theme.spacing(1)
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark
    }
  },
  divider: {
    width: '100%',
    height: 1,
    marginTop: theme.spacing(2)
  }
}));
export { useStyles };