import _Tooltip from "@material-ui/core/esm/Tooltip";
import _Typography from "@material-ui/core/esm/Typography";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import UploadMoreIcon from '@material-ui/icons/AddCircleRounded';
import ChangeIcon from '@material-ui/icons/Replay';
import { messages } from "./ImagePickerMessages";
import { useStyles } from "./ImagePickerStyles";
import { ImagePreview } from "./ImagePreview/ImagePreview";
const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];

const ImagePicker = _ref => {
  let _ref$values = _ref.values,
      values = _ref$values === void 0 ? [] : _ref$values,
      setValues = _ref.setValues,
      _ref$multiple = _ref.multiple,
      multiple = _ref$multiple === void 0 ? true : _ref$multiple,
      Icon = _ref.icon,
      label = _ref.label,
      className = _ref.className,
      error = _ref.error,
      helperText = _ref.helperText,
      _ref$max = _ref.max,
      max = _ref$max === void 0 ? 10 : _ref$max,
      helperTextProps = _ref.helperTextProps,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'text.primary' : _ref$color;

  const _useIntl = useIntl(),
        formatMessage = _useIntl.formatMessage;

  const classes = useStyles({
    color
  });

  const handleUploadImage = _ref2 => {
    let files = _ref2.target.files;

    if (files.length) {
      setValues([...values, ...[...files].filter(file => allowedTypes.includes(file.type)).map(file => ({
        url: URL.createObjectURL(file),
        file
      }))]);
    }
  };

  return React.createElement(React.Fragment, null, _jsx("div", {
    className: "".concat(classes.container, " ").concat(className)
  }, void 0, !values.length ? _jsx("label", {
    htmlFor: "fileInput",
    className: "".concat(classes.uploadImageButton, " ImagePicker-uploadImageButton")
  }, void 0, _jsx(Icon, {
    className: "".concat(classes.uploadIcon, " ImagePicker-uploadIcon")
  }), _jsx(_Typography, {
    variant: "caption",
    className: "".concat(classes.uploadText, " ImagePicker-uploadText")
  }, void 0, label)) : _jsx(Fragment, {}, "image-previews", values.map((value, index) => _jsx(ImagePreview, {
    index: index,
    color: color,
    src: value.url,
    onRemove: () => setValues(values.filter((_, index_) => !(index === index_)))
  }, "imagepicker-image-".concat(index, "-").concat(value.url))), _jsx("label", {
    htmlFor: "fileInput",
    className: "".concat(classes.uploadImageButton, " ImagePicker-uploadImageButton ").concat(values.length >= max && 'disabled')
  }, "imagepicker-upload-button", _jsx(_Tooltip, {
    title: formatMessage(messages.upload)
  }, void 0, multiple ? _jsx(UploadMoreIcon, {
    className: "".concat(classes.uploadMoreIcon, " ImagePicker-uploadMoreIcon ")
  }) : _jsx(ChangeIcon, {
    className: "".concat(classes.uploadMoreIcon, " ImagePicker-uploadMoreIcon")
  })))), _jsx("input", {
    accept: allowedTypes.join(', '),
    onChange: handleUploadImage,
    type: "file",
    multiple: true,
    id: "fileInput",
    className: classes.fileInput,
    disabled: values.length >= max
  })), helperText && React.createElement(_Typography, _extends({
    variant: "caption",
    color: error ? 'error' : 'textSecondary'
  }, helperTextProps), helperText));
};

export { ImagePicker };