import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  confirmationDeleteTitle: {
    id: 'components.ConfirmationDelete.confirmation.delete.title',
    defaultMessage: '¿Estás seguro de eliminar este registro?'
  },
  confirmationDeleteDescription: {
    id: 'components.ConfirmationDelete.confirmation.delete.description',
    defaultMessage: 'Si borras el registro no podrás recuperarlo'
  },
  cancel: {
    id: 'components.ConfirmationDelete.cancel',
    defaultMessage: 'Cancelar'
  },
  delete: {
    id: 'components.ConfirmationDelete.delete',
    defaultMessage: 'Eliminar'
  }
});
export const confirmationMessages = messages;