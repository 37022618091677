import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  remove: {
    id: 'components.ImagePicker.remove',
    defaultMessage: 'Remove'
  },
  upload: {
    id: 'components.ImagePicker.upload',
    defaultMessage: 'Subir imagen'
  }
});