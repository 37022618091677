import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  data: {
    id: 'components.Register.RegisterForm.data',
    defaultMessage: '1. Datos'
  },
  password: {
    id: 'components.Register.RegisterForm.password',
    defaultMessage: '2. Contraseña'
  },
  profileImage: {
    id: 'components.Register.RegisterForm.profileImage',
    defaultMessage: '3. Imagen de perfil'
  },
  requiredError: {
    id: 'components.Register.RegisterForm.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  emailError: {
    id: 'components.Register.RegisterForm.emailError',
    defaultMessage: 'Correo electrónico inválido'
  },
  matchError: {
    id: 'components.Register.RegisterForm.matchError',
    defaultMessage: 'No coincide con la contraseña'
  },
  privacyError: {
    id: 'components.Register.RegisterForm.privacyError',
    defaultMessage: 'El aviso de privacidad debe ser aceptado'
  },
  passwordRegexError: {
    id: 'components.Register.password.regexError',
    defaultMessage: 'La contraseña no cumple con la política de seguridad (Min. 8 caracteres, 1 mayúscula, 1 número y 1 símbolo).'
  }
});