import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  formInstructions: {
    id: 'components.Auth.VerifyAccount.formInstructions',
    defaultMessage: 'Introduce el PIN que recibiste en tu correo'
  },
  buttonSubmit: {
    id: 'components.Auth.VerifyAccount.buttonSubmit',
    defaultMessage: 'Verificar cuenta'
  },
  successVerifyMessage: {
    id: 'components.Auth.VerifyAccount.successVerifyMessage',
    defaultMessage: 'Tu cuenta ha sido verificada exitosamente, ya puedes iniciar sesión.'
  },
  haveAccountText: {
    id: 'components.Auth.VerifyAccount.haveAccountText',
    defaultMessage: '¿Ya tienes una cuenta?'
  },
  pinLabel: {
    id: 'components.Auth.VerifyAccount.pin.label',
    defaultMessage: 'PIN'
  },
  pinPlaceholder: {
    id: 'components.Auth.VerifyAccount.pin.placeholder',
    defaultMessage: 'PIN'
  }
});