import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  specialityLabel: {
    id: 'components.Register.RegisterForm.DataSection.specialityLabel',
    defaultMessage: 'Especialidad'
  },
  specialityPlaceholder: {
    id: 'components.Register.RegisterForm.DataSection.specialityPlaceholder',
    defaultMessage: 'Selecciona una opción...'
  },
  professionalIdLabel: {
    id: 'components.Register.RegisterForm.DataSection.professionalIdLabel',
    defaultMessage: 'Cédula profesional'
  },
  professionalIdPlaceholder: {
    id: 'components.Register.RegisterForm.DataSection.professionalIdPlaceholder',
    defaultMessage: 'Introduce tu cédula profesional'
  },
  nameLabel: {
    id: 'components.Register.RegisterForm.DataSection.nameLabel',
    defaultMessage: 'Nombre'
  },
  namePlaceholder: {
    id: 'components.Register.RegisterForm.DataSection.namePlaceholder',
    defaultMessage: 'Introduce tu nombre completo'
  },
  emailLabel: {
    id: 'components.Register.RegisterForm.DataSection.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  emailPlaceholder: {
    id: 'components.Register.RegisterForm.DataSection.emailPlaceholder',
    defaultMessage: 'Introduce tu correo electrónico'
  },
  stateLabel: {
    id: 'components.Register.RegisterForm.DataSection.stateLabel',
    defaultMessage: 'Estado'
  },
  statePlaceholder: {
    id: 'components.Register.RegisterForm.DataSection.statePlaceholder',
    defaultMessage: 'Selecciona una opción...'
  },
  phoneLabel: {
    id: 'components.Register.RegisterForm.DataSection.phoneLabel',
    defaultMessage: 'Teléfono'
  },
  phonePlaceholder: {
    id: 'components.Register.RegisterForm.DataSection.phonePlaceholder',
    defaultMessage: 'Introduce tu teléfono'
  },
  hospitalLabel: {
    id: 'components.Register.RegisterForm.DataSection.hospitalLabel',
    defaultMessage: 'Clínica u hospital'
  },
  hospitalPlaceholder: {
    id: 'components.Register.RegisterForm.DataSection.hospitalPlaceholder',
    defaultMessage: 'Introduce tu clínica u hospital'
  },
  requiredSectionDescription: {
    id: 'components.Register.RegisterForm.DataSection.requiredSectionDescription',
    defaultMessage: 'Estos datos son necesarios:'
  },
  optionalSectionDescription: {
    id: 'components.Register.RegisterForm.DataSection.optionalSectionDescription',
    defaultMessage: 'Estos datos son opcionales:'
  }
});