import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  chartIcon: {
    color: theme.palette.primary.main,
    fontSize: 55,
    marginBottom: theme.spacing()
  }
}));
export { useStyles };