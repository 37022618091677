import { makeStyles } from '@material-ui/core/styles';
import { hexToRgba } from "../../helpers/colors";
export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '& .apexcharts-pie': {
      '& circle': {
        pointerEvents: 'none'
      }
    },
    '& .apexcharts-pie-series > path': {
      stroke: theme.palette.background.paper,
      strokeWidth: 3
    },
    '& .apexcharts-menu': {
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[9],
      minWidth: 'unset',
      padding: 0,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      border: theme.palette.type === 'light' ? '1px solid #cccccc50' : 'none',
      '& .apexcharts-menu-item': {
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.standard
        }),
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: hexToRgba(theme.palette.text.primary, 0.1)
        }
      }
    },
    '& .apexcharts-tooltip': {
      backgroundColor: "".concat(theme.palette.background.paper, " !important"),
      color: "".concat(theme.palette.text.primary, " !important"),
      border: theme.palette.type === 'light' ? '1px solid #cccccc50 !important' : 'none !important',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[9],
      padding: theme.spacing(0.5),
      paddingTop: theme.spacing(1),
      '& .apexcharts-tooltip-title': {
        backgroundColor: "".concat(theme.palette.background.paper, " !important"),
        border: 'none !important',
        fontWeight: 'bold',
        marginBottom: theme.spacing(0.5),
        padding: '0px 0px 0px 10px !important'
      },
      '& .apexcharts-tooltip-series-group': {
        backgroundColor: 'transparent !important'
      }
    }
  }
}));