import defaultProductLogo from "./svg/company-icon-logo.svg";
import defaultGreetingsLogin from "./svg/default-greetings-login.svg";
import group from "./svg/group.svg";
import key from "./svg/key.svg";
import pen from "./svg/pen.svg";
import accountCircle from "./svg/account-circle.svg";
import companyLogo from "./svg/company-logo.svg";
const Images = {
  defaultProductLogo,
  defaultGreetingsLogin,
  group,
  key,
  pen,
  accountCircle,
  companyLogo
};
export { Images };