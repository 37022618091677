var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, messages as formMessages } from '@octopy/react-form';
import * as formConfig from "../../../utils/form-config";
import { messages } from "./LoginFormMessages";

const LoginForm = _ref => {
  let formikRef = _ref.formikRef,
      handleSubmit = _ref.handleSubmit;
  const intl = useIntl();

  const _useParams = useParams(),
        email = _useParams.email;

  const getInitialValues = initialValues => _objectSpread({
    email: email,
    password: ''
  }, initialValues);

  const getFields = () => [{
    type: 'text',
    name: 'email',
    label: intl.formatMessage(formMessages.emailLabel),
    placeholder: intl.formatMessage(formMessages.emailPlaceholder)
  }, {
    type: 'password',
    name: 'password',
    label: intl.formatMessage(formMessages.passwordLabel),
    placeholder: intl.formatMessage(formMessages.passwordPlaceholder)
  }];

  const requiredFieldError = intl.formatMessage(formMessages.requiredFieldError);
  const invalidEmailError = intl.formatMessage(formMessages.invalidEmailError);

  const getValidationRules = () => Yup.object({
    email: Yup.string().email(invalidEmailError).required(requiredFieldError),
    password: Yup.string().required(requiredFieldError)
  });

  return _jsx(Form, {
    formikRef: formikRef,
    initialValues: getInitialValues(),
    validationRules: getValidationRules(),
    fields: getFields(),
    buttonSubmitLabel: intl.formatMessage(messages.buttonSubmit),
    handleSubmit: handleSubmit,
    showSubmitButton: false,
    inputProps: formConfig.inputProps
  });
};

export { LoginForm };