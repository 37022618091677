import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  submitButton: {
    marginLeft: 'auto',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));
export { useStyles };