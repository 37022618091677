export const hexToRgba = function hexToRgba(hex) {
  let alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  let color = hex;

  if (hex.length === 4) {
    color += "".concat(hex[1]).concat(hex[2]).concat(hex[3]);
  }

  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")");
};