import _TableHead from "@material-ui/core/esm/TableHead";
import _TableRow from "@material-ui/core/esm/TableRow";
import _TableSortLabel from "@material-ui/core/esm/TableSortLabel";
import _Typography from "@material-ui/core/esm/Typography";
import _TableCell from "@material-ui/core/esm/TableCell";
import _Checkbox from "@material-ui/core/esm/Checkbox";
import _size from "lodash/size";
var REACT_ELEMENT_TYPE;

function _jsx(type, props, key, children) { if (!REACT_ELEMENT_TYPE) { REACT_ELEMENT_TYPE = typeof Symbol === "function" && Symbol["for"] && Symbol["for"]("react.element") || 0xeac7; } var defaultProps = type && type.defaultProps; var childrenLength = arguments.length - 3; if (!props && childrenLength !== 0) { props = { children: void 0 }; } if (childrenLength === 1) { props.children = children; } else if (childrenLength > 1) { var childArray = new Array(childrenLength); for (var i = 0; i < childrenLength; i++) { childArray[i] = arguments[i + 3]; } props.children = childArray; } if (props && defaultProps) { for (var propName in defaultProps) { if (props[propName] === void 0) { props[propName] = defaultProps[propName]; } } } else if (!props) { props = defaultProps || {}; } return { $$typeof: REACT_ELEMENT_TYPE, type: type, key: key === undefined ? null : "" + key, ref: null, props: props, _owner: null }; }

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useStyles } from "./TableHeadStyles";
import { tableHeadProps } from "../types";

function TableHead(props) {
  const order = props.order,
        orderBy = props.orderBy,
        rowCount = props.rowCount,
        headLabel = props.headLabel,
        numSelected = props.numSelected,
        onRequestSort = props.onRequestSort,
        onSelectAllClick = props.onSelectAllClick,
        tableHeadStyles = props.tableHeadStyles,
        checkboxHidden = props.checkboxHidden;
  const classes = useStyles();

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return _jsx(_TableHead, {
    className: "".concat(tableHeadStyles)
  }, void 0, _jsx(_TableRow, {}, void 0, !checkboxHidden && _jsx(_TableCell, {
    padding: "checkbox"
  }, void 0, _jsx(_Checkbox, {
    indeterminate: numSelected > 0 && numSelected < rowCount,
    checked: rowCount > 0 && numSelected === rowCount,
    onChange: onSelectAllClick,
    color: "primary"
  })), _size(headLabel) > 0 && headLabel.map(_ref => {
    let key = _ref.key,
        align = _ref.align,
        label = _ref.label;
    return _jsx(_TableCell, {
      align: align ? align : 'left',
      sortDirection: orderBy === key ? order : false
    }, key, _jsx(_TableSortLabel, {
      active: orderBy === key,
      direction: orderBy === key ? order : 'asc',
      onClick: createSortHandler(key)
    }, void 0, align === 'center' && _jsx(ArrowDownwardIcon, {
      className: classes.arrowHidden
    }), label, orderBy === key ? _jsx(_Typography, {
      variant: "body1",
      className: classes.visuallyHidden
    }, void 0, order === 'desc' ? 'sorted descending' : 'sorted ascending') : null));
  })));
}

export { TableHead };