import { shape, string, bool, number, oneOf, arrayOf } from 'prop-types';
const breakpointsType = shape({
  xl: number,
  lg: number,
  sm: number,
  xs: number
});
const fieldType = shape({
  id: string,
  name: string.isRequired,
  type: oneOf(['text', 'password', 'textarea', 'select']),
  placeholder: string.isRequired,
  label: string,
  disabled: bool,
  breakpoints: breakpointsType
}).isRequired;
const inputType = shape({
  variant: oneOf([undefined, 'filled', 'outlined']),
  margin: oneOf(['normal', 'dense']),
  multilineRows: number,
  fullWidth: bool
});
const buttonType = shape({
  variant: oneOf(['contained', 'outlined', 'text']),
  size: oneOf(['large', 'medium', 'small']),
  color: oneOf(['default', 'inherit', 'primary', 'secondary']),
  fullWidth: bool,
  labelColor: string
});
const sectionsType = arrayOf(shape({
  name: string.isRequired,
  title: shape({
    text: string.isRequired
  }),
  description: shape({
    text: string.isRequired
  })
}));
const defaultProps = {
  inputType: {
    variant: 'filled',
    margin: 'dense',
    multilineRows: 4,
    fullWidth: true
  },
  buttonType: {
    variant: 'contained',
    size: 'large',
    color: 'primary',
    fullWidth: true,
    labelColor: 'textPrimary'
  },
  breakpoints: {
    xl: 12,
    lg: 12,
    sm: 12,
    xs: 12
  }
};
export { fieldType, inputType, buttonType, sectionsType, defaultProps };