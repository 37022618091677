import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  formInstructions: {
    id: 'components.Auth.PasswordRecovery.formInstructions',
    defaultMessage: 'Contraseña nueva'
  },
  buttonSubmit: {
    id: 'components.Auth.PasswordRecovery.buttonSubmit',
    defaultMessage: 'Restablecer contraseña'
  },
  successRecoveryMessage: {
    id: 'components.Auth.PasswordRecovery.successRecoveryMessage',
    defaultMessage: 'Se ha restablecido la contraseña exitosamente, ya puedes iniciar sesión.'
  },
  expiredTimeMessage: {
    id: 'components.Auth.PasswordRecovery.expiredTimeMessage',
    defaultMessage: 'Su tiempo para restablecer la contraseña ha expirado, vuelva a intentarlo.'
  },
  haveAccountText: {
    id: 'components.Auth.Register.haveAccountText',
    defaultMessage: '¿Ya tienes una cuenta?'
  },
  pinLabel: {
    id: 'components.Auth.PasswordRecovery.pin.label',
    defaultMessage: 'PIN'
  },
  pinPlaceholder: {
    id: 'components.Auth.PasswordRecovery.pin.placeholder',
    defaultMessage: 'PIN'
  },
  passwordRegexError: {
    id: 'components.Auth.PasswordRecovery.password.regexError',
    defaultMessage: 'La contraseña no cumple con la política de seguridad (Min. 8 caracteres, 1 mayúscula, 1 número y 1 símbolo).'
  }
});